import { createEnvironmentFlags, getFlag } from '@zola-helpers/client/dist/es/featureFlags';
import getEnvironment from '@zola-helpers/client/dist/es/util/environment'; // this could be removed, if you fix up all the places that import getEnvironment

export const FLAGS = {
  // Temporary Feature Flags
  gmPhase1: createEnvironmentFlags(true, true, false), // CE-2
  enhancedPromoMessaging: createEnvironmentFlags(true, true, false), // Enhanced promo messaging on cart items
  featureToggle: createEnvironmentFlags(true, true, false),
  hideUniversalSearchBarInDetailPages: createEnvironmentFlags(true, true, false), // INSPO-6520
  supplyChainFee: createEnvironmentFlags(true, true, false),
  gladlyChat: createEnvironmentFlags(true, true, true), // PE-457
  seatingChartV2Released: createEnvironmentFlags(true, true, true),
  pushSignupIntoCustomization: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/PE-980
  enableOfficiantsAndExtras: createEnvironmentFlags(true, true, false), // PE-1721 Enable couples to find and inquire with officiants and event extras
};

export type FeatureFlag = keyof typeof FLAGS;

const get = (flag: FeatureFlag): boolean => getFlag(FLAGS, flag);

const featureFlags = {
  get,
  getEnvironment,
};

export default featureFlags;
