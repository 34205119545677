import styled from '@emotion/styled';
import { COLORS3, FONT, MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';

import { ANIMATION_DURATION, fadeIn } from '../UniversalSearch/UniversalSearch.styles';

export const Container = styled.div<{ width: number }>`
  padding: ${SPACING.XS} 0;
  width: 100%;
  animation: ${fadeIn} ${ANIMATION_DURATION} linear;
  overflow: auto;

  ${MEDIA_QUERY.DESKTOP} {
    width: ${({ width }) => width}px;
    border: var(--border-element, 1px solid ${COLORS3.BLACK_030});
    border-radius: var(--border-radius-sm, ${SPACING.XS});
    position: absolute;
    margin-top: ${SPACING.XXS};
    background: var(--background-light, ${COLORS3.WHITE_100});
  }
`;

export const Section = styled.section`
  & + & {
    border-top: var(--border-element, 1px solid ${COLORS3.BLACK_030});
  }
`;

export const SectionTitle = styled.h2`
  ${FONT.textSizeCompact(FONT.SIZE.SMALLER)}
  margin: 0 0 ${SPACING.S16} ${SPACING.S16};
  color: var(--text-primary, ${COLORS3.BLACK_100});
`;

export const SectionItems = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
`;

export const SearchItem = styled.li<{ isHighlighted: boolean }>`
  padding: ${SPACING.XS} 0;
  display: flex;
  align-items: center;
  ${MEDIA_QUERY.DESKTOP} {
    background: ${({ isHighlighted }) =>
      isHighlighted
        ? `var(--background-container, ${COLORS3.BLACK_005})`
        : `var(--background-light, ${COLORS3.WHITE_100})`};
  }

  &:hover {
    background: var(--background-container, ${COLORS3.BLACK_005});
  }
`;

export const SearchLink = styled.a<{ isSRP: boolean }>`
  width: 100%;
  padding: 0 ${SPACING.S16} 0 44px;
  display: flex;
  align-items: center;
  font-weight: ${({ isSRP }) => (isSRP ? FONT.WEIGHT.SEMI_BOLD : FONT.WEIGHT.REGULAR)};
  text-decoration: none;
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}

  &:hover, &:visited, &:active, &:focus {
    text-decoration: none;
    color: var(--text-primary, ${COLORS3.BLACK_100});
  }
`;

export const HighlightedCta = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  color: var(--border-element-hover-color, ${COLORS3.BLACK_030});
  ${FONT.textSizeUltraCompact(FONT.SIZE.SMALLER)}
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: none;
  }
`;

export const PressEnter = styled.span`
  border: var(--border-element-hover, 1px solid ${COLORS3.BLACK_030});
  margin-left: ${SPACING.XXS};
  padding: 2px ${SPACING.XS};
  border-radius: ${SPACING.XXS};
`;

export const ItemType = styled.span`
  color: var(--text-tertiary, ${COLORS3.BLACK_050});
  font-weight: ${FONT.WEIGHT.REGULAR};
  ${FONT.textSizeUltraCompact(FONT.SIZE.EXTRA_SMALL)}
`;

export const Icon = styled.span`
  color: var(--text-primary, ${COLORS3.BLACK_100});
  .zui-svg-icon {
    display: flex;
    margin-left: -${SPACING.S32};
  }
`;

export const SearchText = styled.span`
  color: var(--text-primary, ${COLORS3.BLACK_100});
`;

export const HighlightedSearchTerm = styled.span`
  font-weight: ${FONT.WEIGHT.REGULAR};
`;
